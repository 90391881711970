<template>
  <div>
    <router-view />
  </div>
</template>
<script>
let Load;
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
